export const socialsData = {
    github: 'https://github.com/muhammadhamza12',
    facebook: '',
    linkedIn: 'https://www.linkedin.com/in/muhammad-hamza-haneef-qureshi-112a2312b',
    instagram: '',
    codepen: '',
    twitter: 'https://twitter.com/muhamma46958013',
    reddit: '',
    blogger: '',
    medium: '',
    stackOverflow: '',
    gitlab: '',
    youtube: ''
}